import { Component, OnInit } from '@angular/core';
import { IMqttMessage, MqttService } from 'ngx-mqtt';

import {environment} from '../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { MqttConexionService } from '../services/mqtt.service';
import { ProviderService } from '../services/provider.service';
import { Paciente } from '../interfaces/paciente';

@Component({
  selector: 'app-sinurgencia',
  templateUrl: './sinurgencia.component.html',
  styleUrls: ['./sinurgencia.component.scss']
})
export class SinurgenciaComponent implements OnInit {

  class_states: string[] = [
    'bg-normal',
    'bg-warning',
    'bg-danger',
  ]

  currentDate: Date = new Date();
  private client: any;
  public items: any[] = [];
  private topic = 'pacientes_box';

  private queue_audio_list: { run:string | null, box:string | null}[] = [];
  private can_play:boolean = true;
  private can_play_unlocker_count:number = 0;

  private data_en_espera: Paciente[] = [];
  public paginate_index:number = -1;
  public paginate_limit:number = 0;

  public pacientes:{'Paciente Llamado':Paciente[], 'En espera':Paciente[]} = {
    'Paciente Llamado': [],
    'En espera': [],
  }

  private sector:number = 0;
  private centro:number = 0;

  constructor(private mqttService: MqttConexionService,private dataService: ProviderService, private route: ActivatedRoute) {

    this.route.queryParams.subscribe(params => {
      const sector = params['sector'];
      const centro = params['centro'];

      this.sector = sector ?? 0;
      this.centro = centro ?? 0;
    });

    console.log(this.sector, this.centro);
  }

  async ngOnInit(): Promise<void> {
    this.actualizarPacientes();
    this.mqtt();
    setInterval(() => {
      this.actualizarPacientes();
    }, 15 * 1000);

    this.queue_audio_list = [];
    this.queuePlayer();
  }

  private async queuePlayer(){
    setTimeout(async () => {
      await this.llamarPaciente();
      this.queuePlayer();
    }, 1000);
  }

  private actualizarPacientes(){
    this.dataService.getColinaData(this.sector, this.centro).then((result) => {

      this.pacientes['Paciente Llamado'] = result.data.filter((paciente:any) => paciente.Estado === 'Paciente Llamado').sort((a:any, b:any) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());

      this.data_en_espera = result.data.filter((paciente:any) => paciente.Estado === 'En espera').sort((a:any, b:any) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime());

      this.paginate_limit = Math.ceil(this.data_en_espera.length / 7);
      this.startPagination();
    });
      this.currentDate = new Date();
  }

  private startPagination(){
    this.enEsperaPagination();
  }

  private enEsperaPagination(){
    if(this.paginate_index < this.paginate_limit - 1) this.paginate_index++;
    else this.paginate_index = 0;

    this.pacientes['En espera'] = this.data_en_espera.slice(this.paginate_index * 7, (this.paginate_index + 1) * 7);
  }


  private onConnected(): void {
    this.client.subscribe(this.topic);
    this.client.onMessageArrived = this.onMessageReceived.bind(this);
  }

  private onMessageReceived(message: any): void {
    const payload = JSON.parse(message.payloadString);
    this.updateTable(payload);
  }

  private mqtt(){
    const new_topic = this.mqttService.topic('').subscribe((message:IMqttMessage) => {
      const payload = JSON.parse(message.payload.toString());

      console.log(payload);

      if(payload.sector != this.sector || payload.centro != this.centro) return;

      if(payload.run && payload.box) this.queue_audio_list.push({run:payload.run, box:payload.box});

      this.actualizarPacientes();
    });

    this.mqttService.topics.push(new_topic);
  }

  private async llamarPaciente(){

    if(this.queue_audio_list.length && this.can_play){
      try {
        this.can_play = false;
        this.can_play_unlocker_count = 0;
        const {run, box} = this.queue_audio_list.shift() ?? {run: null, box: null};

        let new_audio =  await new Promise (resolve => resolve(new Audio(`${environment.BACKEND_AUX}/api/paciente/play?run=${run}&box=${box}`)));

        await this.playCallAudio(new_audio);

        new_audio = null;
      } catch (error) {
        console.error(error);
      } finally{
        this.can_play = true;
      }
    }else{
      this.can_play_unlocker_count++;
      if(this.can_play_unlocker_count > 10){
        this.can_play = true;
        this.can_play_unlocker_count = 0;
      }
    }
  }

  public getCurrentLlamadoState(cant_llamado:number){
    return this.class_states[cant_llamado > this.class_states.length ? this.class_states.length - 1 : cant_llamado - 1];
  }

  private playCallAudio(audio:any){
    try {
      return new Promise(response=>{
        audio.play();
        audio.onended = response;
        audio.onerror = response;
    });
    } catch (error) {
      console.error(error);
      return new Promise(response=>response);
    }
  }


  async updateTable(data: any): Promise<void> {

    const index = this.items.findIndex(item => item.id === data.id);
    if (index >= 0) {
      this.items[index] = data;
    } else {
      this.items.push(data);
    }
  }

}
