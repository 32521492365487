import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SinurgenciaComponent } from './sinurgencia/sinurgencia.component';
const routes: Routes = [
  {
    path: 'sinurgencia',
    component: SinurgenciaComponent,
    pathMatch: 'full',
  }, 

  {
    path: ' ',
    component: SinurgenciaComponent,
    pathMatch: 'full',
  },
  {
    path: '**',
    component: SinurgenciaComponent,
    pathMatch: 'full',
  }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
