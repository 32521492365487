export const environment = {
    production: true,
    BACKEND_AUX: 'https://api.saludcolina.ubq.cl',
    mqtt: {
        server: 'socket.turnomate.ubq.cl', // o el servidor MQTT deseado
        port: 443, // puerto MQTT predeterminado
        path: '',
        protocol: 'wss'
    },
};
