import { Injectable } from '@angular/core';
import { IMqttMessage, MqttService } from 'ngx-mqtt';
import { Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MqttConexionService {
  constructor(private mqttService: MqttService) {}

  topics:Subscription[] = [];

  topic(topic: string): Observable<IMqttMessage> {
    let topicName = `cs/llamado/tv/llamado`;

    console.log('Subscribing to topic: ', topicName);

    const observer = this.mqttService.observe(topicName, {qos: 2});

    console.log(observer);

    return observer;
  }

  async publish(topic: string, message: string) : Promise<{status:boolean}>{
    try {
      await this.mqttService.publish(`cs/llamado/tv/${topic}`, message).toPromise();

      return {status: true};

    } catch (error) {
      console.error(error);
      return {status: false};
    }
  }

  unsubscribeAll(){
    this.topics.forEach((topic:Subscription) => topic.unsubscribe());
    this.topics = [];
  }

  startDetection(){
    console.log('Tracking mqtt events!');
    this.mqttService.onOffline.subscribe(event => {
      console.log('MQTT OFFLINE', event);
    });
    this.mqttService.onReconnect.subscribe(event => {
      console.log('MQTT RECONNECT', event);
    });
  }
}
