import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Buffer } from 'buffer';

@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  COLINA_USERNAME = 'PruebaUrgencias';
  COLINA_PASSWORD = 'eb42632606e9261f';

  EXTERNAL_API_COLINA_2 = 'colina';

  constructor(private http: HttpClient) {}

  base64Str = Buffer.from(this.COLINA_USERNAME +':'+this.COLINA_PASSWORD).toString('base64')

  getData(): Observable<any[]> {
    const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': 'Basic ' + this.base64Str,
        })
      };
    return this.http.get<any[]>('/colina_box', httpOptions);
  }

  async getColinaData(sector:number, centro:number):Promise<any> {
    let pacientes = (await this.http.get<any>(`${environment.BACKEND_AUX}/api/paciente/centro?Centro=${centro}&Sector=${sector}`).toPromise());
    console.log(pacientes);
    return pacientes;
  }
}
