import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SinurgenciaComponent } from './sinurgencia/sinurgencia.component';
import { IMqttServiceOptions, MqttModule } from 'ngx-mqtt';
import { HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MqttConexionService } from './services/mqtt.service';

registerLocaleData(es);

const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: environment.mqtt.server,
  port: environment.mqtt.port,
  protocol: (environment.mqtt.protocol === "wss") ? "wss" : "ws",
  path: '',
};

@NgModule({
  declarations: [
    AppComponent,
    SinurgenciaComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    HttpClientModule
  ],
  bootstrap: [AppComponent],
  providers: [
    {provide: LOCALE_ID, useValue: 'es-CL'},
    MqttConexionService,
]
})
export class AppModule { }
